import React from 'react'
import '../components/css/layout.css'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../components/css/policy.css'
import Seo from '../components/Seo'

const Policy = () => (
  <div>
    <Seo
      title="Digipay PH | Policy"
      description="Digipay is a digital payments and financial services platform with
a mobile wallet, an industry leading biller ecosystem,
and an interoperable backend."
      keywords="digipay.ph/policy, Policy, Statement, Statement of Policy, Scope, Scope and Acceptance, Acceptance, Privacy, Notice, Website Visitors"
    />
    <Header></Header>
    <div className="policy">
      <h3 align="center" className="font-weight-bold">
        DIGIPAY PRIVACY POLICY
      </h3>
      <h6 align="center">FSG Technology Ventures, INC.</h6>
      <h6 align="center" className="date-terms">
        Effective as of 14 April 2020
      </h6>

      <h5>Statement of Policy</h5>
      <p>
        <span className="policy__company-name">
          FSG TECHNOLOGY VENTURES, INC., (“DIGIPAY”){' '}
        </span>{' '}
        recognizes its responsibilities under Republic Act No. 10173, otherwise
        known as the Data Privacy Act of 2012, and its Implementing Rules and
        Regulations, as may be amended from time to time (collectively, “Data
        Privacy Laws”) and is committed to safeguarding your personal and
        sensitive personal information in the course of your transactions with
        DIGIPAY, its subsidiaries, affiliates, and third parties.
      </p>
      <h5>Scope and Acceptance</h5>
      <p>
        This Privacy Notice applies to the personal information and sensitive
        personal information obtained from users and online visitors of
        DIGIPAY’s website, which are either manually or automatically collected,
        used, disclosed or otherwise processed by DIGIPAY for purposes of
        responding to your inquiries, requests and feedback/complaints. By
        accessing our website or by giving DIGIPAY personal information or
        sensitive personal information, you accept the policies and practices
        described in this Privacy Notice.
      </p>

      <h5>Digipay’s Privacy Notice for Website Visitors</h5>
      <h6>Collection and Processing of Information</h6>
      <p>
        Should you submit inquiries, requests or feedback/complaints to DIGIPAY
        manually or electronically, DIGIPAY shall collect the following from
        you:
      </p>
      <ul>
        <li>Name</li>
        <li>
          Contact Information (e.g., phone/mobile number and e-mail address)
        </li>
      </ul>
      <p>
        In addition, DIGIPAY uses third-party services (i.e., Google Analytics,
        Rollbar, and Cloudflare) which may employ technologies such as cookies
        in order to collect non-personally identifiable information from
        visitors of its website. This information is utilized in order to
        analyze DIGIPAY’s web traffic, or how you, online visitors of the
        DIGIPAY website, make use of the DIGIPAY website, and includes the
        following:
      </p>
      <ul>
        <li>
          Browser and Device Information (e.g., device type, operating system,
          brand and model, IP address, and web browser type and version)
        </li>
        <li>
          Visitor Information (e.g., visitor’s age and gender, time, date and
          geolocation of visit, pages of the website visited)
        </li>
      </ul>
      <h6>Use of Information</h6>
      <p>
        The above information is processed and may be disclosed to DIGIPAY’s
        subsidiaries, affiliates, and third parties such as service providers
        and government authorities for the following purposes:
      </p>
      <ul>
        <li>
          To document, properly address, and provide responses to your
          inquiries, requests or feedback/complaints;
        </li>
        <li>
          To inform you about our new products, services, offers and promotions;
        </li>
        <li>
          To conduct research and analysis for marketing purposes, profiling,
          and product/website/application development;
        </li>
        <li>
          To perform other processing that may be required under relevant laws
          and regulations; and
        </li>
        <li>
          To comply with reporting obligations to governmental authorities under
          applicable laws.
        </li>
      </ul>
      <h6>Maintaining the Security of Your Information</h6>
      <p>
        DIGIPAY implements organizational security and employs physical and
        technical measures to ensure that your personal information and
        sensitive personal information remains secure and confidential. DIGIPAY
        implements physical, electronic and procedural safeguards for proper
        handling of information. DIGIPAY will ensure that collectors, users,
        processors, and recipients of your personal information or sensitive
        personal information adhere to strict confidentiality and security
        policies aligned with DIGIPAY’s standards which are compliant with Data
        Privacy Laws.
      </p>
      <h6>Storage, Access and Correction</h6>
      <p>
        Your personal information or sensitive personal information shall be
        stored for a period not exceeding three (3) years from the conclusion of
        your last transaction or as may be required by applicable law, whichever
        is longer.
      </p>
      <p>
        Should you wish to have access to your personal information or sensitive
        personal information or should you need to update or correct the same,
        or withdraw any consent you have previously given in relation to the use
        of your information, you may communicate with DIGIPAY’s Data Protection
        Officer through the contact details provided below:
      </p>
      <p>Phone: +632 8254 6621</p>
      <p>Email: data@digipay.ph</p>
      <p>
        Address: 6/F G Business Hub
        <br />
        728 Shaw Blvd., Brgy. Wack-wack, <br />
        Greenhills East, Mandaluyong City, <br />
        Philippines 1552
      </p>
      <p>
        You may also wish to seek the assistance of the National Privacy
        Commission, or access the Data Privacy Act at{' '}
        <a className="inline-link" href="https://www.privacy.gov.ph/">
          www.privacy.gov.ph
        </a>
        .
      </p>
      <h5>Amendment</h5>
      <p>
        This Privacy Notice may be modified or amended from time to time to
        ensure consistency with any changes in applicable laws and regulations.
        The updated version of this Privacy Notice will be posted on DIGIPAY’s
        website.
      </p>
    </div>
    <Footer></Footer>
  </div>
)
export default Policy
